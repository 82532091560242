<template>
	<!-- prettier-ignore -->
	<!-- <pre>
		<router-link :to="{ name: 'User', params: { username: 'themoenen' } }">Moenen</router-link>
		<router-link :to="{ name: 'User', params: { username: 'stoffel_vandenb' } }">Stoffel</router-link>
		<router-link :to="{ name: 'UserList', params: { username: 'suzan-hamer', namePath: 'people-of-color-in-art-arent-t' }}">Suzan List</router-link>
		<router-link :to="{ name: 'UserList', params: { username: 'themoenen', namePath: 'owls' }}">Moenen List 1</router-link>
		<router-link :to="{ name: 'UserList', params: { username: 'themoenen', namePath: 'blobs' }}">Moenen List 2</router-link>
	</pre> -->

	<template v-if="user">
		<!-- Edit list state -->
		<ClusterEdit
			v-if="$isLoggedIn && pageUserStore.isList && pageUserStore.listEditMode"
			:entity="pageUserStore.list"
			type="list"
			@exit="pageUserStore.toggleListEditMode(false)"
			@update="onUpdateList"
		/>

		<!-- View state -->
		<template v-else>
			<FloatActions :rightAlign="true" :responsive="{ tablet: 0.9, mobile: 0.8 }" />
			<AdminControls
				v-if="$isAdmin"
				:entityType="pageUserStore.isList ? 'list' : 'user'"
				:entity="pageUserStore.isList ? list : user"
			/>
			<UserHeader v-if="!user.isMe" :user="user" :loading="loading" />
			<div v-if="user.isMe" id="my-username">@{{ user.username }}</div>
			<NavSub v-if="showNav" :items="navItems" :exact="true" :routed="true" class="main" />

			<!-- https://next.router.vuejs.org/api/#route -->
			<router-view v-slot="{ Component }">
				<keep-alive>
					<component :is="Component" :user="user" />
				</keep-alive>
			</router-view>
		</template>
	</template>
</template>

<script>
export default {
	name: 'UserMain',
}
</script>

<script setup>
// Vue functions
import { computed, watch, onServerPrefetch, onMounted, onBeforeUnmount, reactive, toRefs, inject } from 'vue'
import { useRouter, useRoute } from 'vue-router'

// Stores
import { usePageUserStore } from '@/stores/PageUserStore'
import { useApiStore } from '@/stores/ApiStore'
import { useHttpErrorStore } from '@/stores/HttpErrorStore'

// Components
import ClusterEdit from '@/views/Room/ClusterEdit'
import FloatActions from '@/components/FloatActions'
import AdminControls from '@/components/AdminControls/AdminControls'
import UserHeader from '@/views/User/UserHeader'
import NavSub from '@/components/NavSub'
// import BaseLoading from '@/components/BaseLoading'

// Layout
// import UserCollection from '@/views/User/UserCollection'

// Internal
import { injectMetaData } from '@/use/MetaData'
import { possessive, prettyNr } from '@/use/StringMagic'
import { isSSRClient } from '@/use/Base'
import flash from '@/use/FlashAlert'

const props = defineProps({
	username: {
		type: String,
		required: true,
	},
	namePath: String,
})

// Load user
let { pageUserStore, loading, user, list, onUpdateList } = loadContent(props)

// Set layout
let { navItems, showNav } = renderNav(user)

// Assemble & inject meta data
createMetaData(user)

/**
 * Load user info
 */
function loadContent(props) {
	const pageUserStore = usePageUserStore()
	const httpErrorStore = useHttpErrorStore()
	const router = useRouter()
	const route = useRoute()
	const { username } = toRefs(props)
	const $myUsername = inject('$myUsername')

	// Load API
	const apiStore = useApiStore()
	const userApi = apiStore.loadApi('user')

	// Reactive data
	const state = reactive({
		loading: !isSSRClient,
		user: computed(() => pageUserStore.user),
		list: computed(() => pageUserStore.list),
		usernameNamePath: computed(() => {
			return String(props.username + props.namePath)
		}),
	})

	// Server-side initiation
	onServerPrefetch(async () => {
		await _loadUser()
	})

	// Client-side initiation
	onMounted(async () => {
		// Only load page if it wasn't already fetched server-side (development)
		const isHydrating = !!pageUserStore.user
		if (!isHydrating) {
			await _loadUser()
		}
	})

	onBeforeUnmount(() => {
		// Required to avoid the last visited itm to linger after you return from another page.
		pageUserStore.$reset()
		state.loading = true
	})

	// Reload content whenever path changes
	const { usernameNamePath } = toRefs(state)
	watch(usernameNamePath, () => {
		_loadUser()
	})

	// Show user loader again when swapping user profiles.
	watch(
		() => props.username,
		() => {
			state.loading = true
		}
	)

	// Return data
	return { pageUserStore, ...toRefs(state), onUpdateList }

	//
	//

	function onUpdateList(updatedList) {
		// Redirect if namePath changes.
		if (updatedList.namePath != pageUserStore.list.namePath) {
			router.push({
				name: 'UserList',
				params: { username: $myUsername, namePath: updatedList.namePath },
			})
		} else {
			_loadUser()
		}

		// Hide edit form.
		pageUserStore.toggleListEditMode(false)
	}

	// Load user data
	async function _loadUser(optionalUsername) {
		// console.log('_loadUser')
		const result = await userApi.get(optionalUsername || props.username, {
			includeArtworks: false,
			includeLists: true,
			includeRooms: true,
		})
		if (!result || !result.status)
			console.log(
				`r_user.js loadUser() --> No result for ${username.value}: `,
				result,
				router.currentRoute.value.path
			)

		const { status, statusText, data } = result || {} // Edge case when result returns empty
		if (status == 200 && data && data.user) {
			pageUserStore.storeUser(data.user)

			// if (newNamePath && newNamePath != oldNamePath) {
			// 	// Load list
			// 	pageUserStore.setList({ namePath: newNamePath })
			// } else {
			// 	// Unload list
			// 	pageUserStore.unsetList()
			// }

			if (props.namePath) {
				// Load list
				const listExists =
					data.user.lists && data.user.lists.find(list => list.namePath == props.namePath)

				if (listExists) {
					// Load list
					pageUserStore.setList(props.namePath)
				} else {
					// console.log('REDIRECT')
					// The requested list does not exist, forward to the main collection
					const redirect = {
						name: 'User',
						params: { username: props.username },
						// query: { ...route.query },
					}
					flash(`The list "${props.namePath}" does not exist.`, { type: 'error' })
					router.push(redirect)
				}
			} else {
				// Unload list
				pageUserStore.unsetList()
			}
		} else if (status == 301) {
			// The user's username has changed, forward to correct URL.
			const redirect = {
				name: 'User',
				params: { ...route.params },
				query: { ...route.query },
			}
			// 301 returns new namePath and category as data
			redirect.params.username = data
			router.push(redirect)
		} else {
			httpErrorStore.setError({
				type: 'user',
				status,
				statusText,
				origin: 'User: Layout.vue',
			})
		}
		state.loading = false
	}
}

/**
 * Set navigation
 */
function renderNav(user) {
	const pageUserStore = usePageUserStore()

	const state = reactive({
		navItems: computed(() => {
			const username = pageUserStore.user ? pageUserStore.user.username : 'me'
			const collectionLink = pageUserStore.list.id
				? {
						name: 'UserList',
						params: {
							username,
							namePath: pageUserStore.list.namePath,
						},
				  }
				: {
						name: 'User',
						params: { username },
				  }
			return [
				{
					display: 'Collection',
					to: collectionLink,
				},
				{
					display: 'Rooms',
					to: {
						name: 'UserRooms',
						params: { username },
					},
				},
			]
		}),
		showNav: computed(() => {
			if (!user.value) return
			return user.value.isMe || (user.value && user.value.roomIds && user.value.roomCount)
		}),
	})

	return toRefs(state)
}

/**
 * Assemble & inject meta data
 */

// prettier-ignore
function createMetaData(user) {
	const route = useRoute()
	const $imperial = inject('$imperial')

	// Construct meta data
	const pageName = route.matched[1] ? route.matched[1].name : ''
	let title, description

	if (pageName == 'UserRooms') {
		// Rooms overview
		title = computed(() => (user.value ? `${possessive(user.value.name)} Rooms` : null))
		const roomCount = computed(() => prettyNr(user.value ? user.value.roomCount : '', $imperial))
		description = computed(() => `See the ${roomCount.value} rooms ${user.value ? user.value.name : 'this person'} has curated.`)
	} else {
		// Default Collection page
		title = computed(() => user.value ? `${possessive(user.value.name)} Collection` : null )
		const artworkCount = computed(() => prettyNr(user.value ? user.value.artworkCount : '', $imperial))
		description = computed(() => `See the ${artworkCount.value} artworks ${user.value ? user.value.name : 'this person'} has collected.`)
	}

	const imgSrc = computed(() => user.value && user.value.previewArtwork ? 'https://arthur.io' + user.value.previewArtwork.views[0].src.replace(/__SIZE__/, 'large') : null)
	const imgWidth = computed(() => user.value && user.value.previewArtwork ? user.value.previewArtwork.views[0].width : null)
	const imgHeight = computed(() => user.value && user.value.previewArtwork ? user.value.previewArtwork.views[0].height : null)
	const noIndex = false

	// Inject meta data
	injectMetaData({ title, description, imgSrc, imgWidth, imgHeight, noIndex })
}
</script>

<style lang="scss" scoped>
// My username
#my-username {
	// background: plum;
	position: absolute;
	right: calc(var(--pp) + 1rem);
	top: calc(var(--pp) - 0.1rem);
	height: 0.5rem;
	line-height: 0.5rem;
	font-style: italic;
	font-weight: 500;
	max-width: calc(100% - 3rem - var(--pp) * 2);

	// Truncate
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: $regular;
}

// Subnav top padding
#header + .subnav-wrap {
	margin-top: 0.4rem;
}

/* Page loading state */
.loading-user {
	height: 100%;
}
.loading-user .header {
	padding: 0.4rem;
	min-height: 0.3rem;
	background: rgba(0, 0, 10, 0.85);
}
@media only screen and (max-width: $tablet) {
	#my-username {
		right: calc(var(--pp) + 0.5rem);
		max-width: calc(100% - 1rem - var(--pp) * 2);
	}
}
</style>
