<template>
	<picture>
		<source :srcset="srcsetWebp" type="image/webp" />
		<source :srcset="srcsetDefault" type="image/jpeg" />
		<img class="profile-pic" :class="{ mini, small }" :src="src" :alt="alt" loading="lazy" />
	</picture>
</template>

<script>
export default {
	name: 'BaseImageProfile',
	props: {
		entity: {
			type: Object,
			required: true,
		},
		size: String, // small / mini
	},
	computed: {
		srcsetWebp() {
			return this.entity.profilePicSrc.srcsetWebp
		},
		srcsetDefault() {
			return this.entity.profilePicSrc.srcsetDefault
		},
		src() {
			return this.entity.profilePicSrc.src
		},
		alt() {
			return this.entity.name
		},
		mini() {
			return this.size == 'mini'
		},
		small() {
			return this.size == 'small'
		},
	},
}
</script>

<style scoped>
img.profile-pic {
	width: 1rem;
	height: 1rem;
	object-fit: cover;
	border-radius: 1rem;
	border: solid 0.03rem #eee;
}
img.profile-pic.mini {
	width: 0.3rem;
	height: 0.3rem;
}
</style>
