<template>
	<template v-if="loading">
		<div id="header">
			<div class="content-wrap">
				<h1 class="skinny">Loading...</h1>
			</div>
		</div>
	</template>
	<div v-if="user && !loading" id="header">
		<!-- <pre>{{ user }}</pre> -->
		<div class="content-wrap">
			<!-- This will need to become conponents once we start using profilepics everywehre -->
			<!-- <img
				v-if="user.profilePic"
				class="profile-pic"
				:srcset="`${user.profilePic.replace(/\/2x\//, '/1x/')} 1x, ${user.profilePic} 2x`"
				:src="user.profilePic"
			/> -->
			<BaseImageProfile v-if="user.profilePic" :entity="user" />
			<div class="text-content">
				<h1 class="skinny">{{ user.name }}</h1>
				<div v-if="byline" v-html="byline" class="byline"></div>
				<ButtonFollow :inverse="true" />
				<!-- <div v-if="user.about" class="sep"></div> -->
				<!-- <div v-if="user.about">{{ user.about }}</div> -->
				<!-- <div v-if="!user.accessLevel">
					<br />Is this you? <a href="#" @click="itsMe()">Click here</a>.
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
// Stores
import { useDialogStore } from '@/stores/DialogStore'

// Components
import BaseImageProfile from '@/components/BaseImageProfile'
import ButtonFollow from '@/components/ButtonFollow'

export default {
	name: 'UserHeader',
	components: {
		BaseImageProfile,
		ButtonFollow,
	},
	props: {
		user: {
			type: Object,
			required: true,
		},
		loading: Boolean,
	},
	setup() {
		const dialogStore = useDialogStore()
		return { dialogStore }
	},
	computed: {
		byline() {
			return null
			// return 'Curator'
		},
		byline1() {
			// Create byline
			const sep = ' &nbsp;&nbsp;•&nbsp;&nbsp; '
			const byline = []
			// Location
			if (this.user.location) byline.push(this.user.location)

			// if (this.user.created) {
			// 	const date = moment(this.user.created).format('MMMM YYYY')
			// 	byline.push('Joined ' + date)
			// }

			// if (this.user.links) {
			// 	const links = this.user.links.map(url => {
			// 		return `<a target="_blank" href="${url}" class="web" rel="nofollow">${url.replace(
			// 			/http(s*):\/\/(www.)*/,
			// 			''
			// 		)}</a>`
			// 	})
			// 	byline.push(links.join(' '))
			// }

			// Combined
			return byline.join(sep)
		},
	},
	methods: {
		itsMe() {
			const msg = `<p>Unfortunately it's not possible to claim your account <em>just yet</em>.</p>
				<p>If you'd like to remove it instead, please shoot us an email at
				<a href='mailto:hello@arthur.io'>hello@arthur.io</a> and make sure to include the link to your profile.</p>`
			this.dialogStore.dialog(msg, {
				html: true,
				title: `Hello ${this.user.name}`,
				hideCancel: true,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
/**
 * Wrap
 */

#header {
	// background: rgba(0, 0, 10, 0.85);
	background: $accent-user;
	padding: 0.6rem;
	display: flex;
	align-items: center;
	min-height: 1.9rem;
	clip-path: polygon(0 0, 100% 0, 100% calc(100% - 0.2rem), 0 100%);
}
#header .content-wrap {
	// background: darkred;
	// max-width: 5rem;
	width: 100%;
	display: flex;
	align-items: center;
	font-size: 0;
	line-height: 0;
}

/**
 * Profile pic
 */
#header:deep() img.profile-pic {
	margin: -0.15rem;
	margin-right: 0.3rem;
}

/**
 * Text content
 */
#header .text-content {
	// background: darkgreen;
	gap: 0.05rem;
}
#header h1 {
	margin-bottom: 0.1rem;
	padding-right: 1rem;
}
#header .byline {
	// background: darkcyan;
	min-height: 0.2rem;
	line-height: 0.2rem;
	margin-top: -0.1rem;
	margin-bottom: 0.1rem;
	color: $white-50;
}
#header button {
	// background: red;
	display: inline-block;
	// flex: 0 0;
}
#header .sep {
	width: 0.3rem;
	height: 0.01rem;
	background: #fff;
	opacity: 0.2;
	margin: 0.2rem auto;
}

// #header:deep() a.web {
// 	margin: 0 0.05rem;
// }

@media only screen and (max-width: $tablet) {
	#header {
		margin-top: -0.8rem;
		padding-top: 1.4rem;
	}
}

@media only screen and (max-width: $mobile) {
	#header .content-wrap {
		// background: darkblue;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
	#header:deep() img.profile-pic {
		margin-right: 0;
		margin-bottom: 0.2rem;
	}
	#header h1 {
		padding: 0;
	}
}
</style>
